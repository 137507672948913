<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="review-sec mt-0">

        <div class="review-header">
          <h4>Check Availability</h4>
        </div>
        <div class="">
          <form id="Valory_480" @submit.prevent="sendEmail">
            <ul>
              <li class="column-group-main">
                <div class="input-block m-0">
                  <label>Pickup Date and Time</label>
                </div>
                <div class="input-block-wrap sidebar-form">
                    <div class="input-block me-2">
                      <div class="group-img">
                        <input type="date" id="date" v-model="form.date" name="date" required min="new Date()" style="margin: 0.5rem; padding: 0.8rem; width: 80%; border-radius: 5px; background:#FCFBFB; border:1px solid #F4F4F4;">

                      </div>
                    </div>
                    <div class="input-block me-2">
                      <div class="group-img">
                        <input type="time" id="time" v-model="form.time" name="time" required min="10:00" max="16:00" style="margin: 0.5rem; padding: 0.8rem; width: 80%; border-radius: 5px; background:#FCFBFB; border:1px solid #F4F4F4;">

                      </div>

                    </div>
                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Choose Package</label>
                  <div class="input-block me-2">

                    <div class="form-group">
                      <select id="option" class="form-control" v-model="form.package" name="option" required>
                        <option style="display:none"></option>
                        <option>2-Hours</option>
                        <option>4-Hours</option>
                        <option>8-Hours</option>
                      </select>
                    </div>
                    <label>Number of persons</label>
                    <div class="group-img">
                      <input
                          class="form-control"
                          placeholder=""
                          type="text"
                          v-model="form.people"
                          name="persons"
                          required
                      />
                    </div>
                  </div>
                </div>
                <div class="input-block">
                  <div class="form-check">
                    <label class="form-check-label" for="exampleCheck1">Do you need a skipper? (additional charge +70€)</label>
                    <input type="checkbox" class="form-check-input" v-model="form.skipper" id="exampleCheck1" name="skipper">
                  </div>
                  <div class="form-check">
                    <label class="form-check-label" for="exampleCheck1">Do you need a SUP? (additional charge +20€)</label>
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="form.sup" name="sup">
                  </div>
                </div>
              </li>


              <li class="column-group-main">
                <div class="input-block">
                  <label>Full Name</label>
                  <div class="group-img">
                    <input
                        v-model="form.name"
                        class="form-control"
                        placeholder=""
                        type="text"
                        name="name"
                        required
                    />
                  </div>
                  <label>E-mail</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        placeholder=""
                        type="Email"
                        v-model="form.email"
                        name="email"
                        required
                    />
                  </div>
                  <label>Phone Number</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        placeholder=""
                        type="tel"
                        v-model="form.number"
                        name="number"
                        required
                    />
                  </div>
                </div>
              </li>


              <li class="column-group-last">
                <div class="input-block mb-0">
                  <div class="search-btn">
                    <button class="btn btn-primary check-available w-100">
                      Check Availability
                    </button>

                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div class="review-sec share-car mt-0">
        <div class="review-header">
          <h4>Our Price List</h4>
        </div>
        <div class="price-list">
          <table>
            <thead>
            <tr>
              <th>Service</th>
              <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in priceList" :key="index">
              <td>{{ item.service }}</td>
              <td>{{ item.price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import emailjs from "@emailjs/browser";
const currentDate1 = ref(new Date());
//import { add } from 'date-fns'

export default {
  data() {
    return {
      startdate1: currentDate1,
      dateFormat: "dd-MM-yyyy",
      form: {
        boat: "480",
        name: "",
        email: "",
        number: "",
        date: "",
        time: "",
        package: "",
        people: "",
        skipper: "",
        sup: "",
      },
      priceList: [
        {service: 'Boat Rental - 2 Hour', price: '140€'},
        {service: 'Boat Rental - 4 Hour', price: '199€'},
        {service: 'Boat Rental - 8 Hour', price: '340€'}
      ]
    };
  },
  methods: {
    sendEmail() {
      this.loading = true;

      // Define EmailJS parameters
      const serviceID = "service_4r8bfkn"; // Replace with your EmailJS Service ID
      const templateID = "template_gbjuacq"; // Replace with your Template ID
      const publicKey = "1MxlBEyzWn5yyeru0"; // Replace with your Public Key

      // Send email using EmailJS
      emailjs
          .send(serviceID, templateID, this.form, publicKey)
          .then((response) => {
            this.responseMessage = "Message sent successfully!";
            this.isSuccess = true;
            console.log("Success!", response.status, response.text);
          })
          .catch((error) => {
            this.responseMessage = "Failed to send message. Please try again.";
            this.isSuccess = false;
            console.error("Failed...", error);
          })
          .finally(() => {
            this.loading = false;
            // Optionally clear the form fields
            this.form.name = "";
            this.form.email = "";
            this.form.number = "";
            this.form.date = "";
            this.form.time = "";
            this.form.package = "";
            this.form.people = "";
            this.form.skipper = "";
            this.form.sup = "";

          });
    },
  },
};
//const disabledDate1 = ref(new Date("2024-5-20"))
//<!--:disabledDates="{ dates: [disabledDate1,new Date('2024-5-28')] }" -->



// let disabledDate = ref((new Date()))[]
// disabledDate.value = new Date("2024-5-20")+new Date("2024-5-22");


</script>
<style scoped>
.price-list {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.price-list h1 {
  text-align: center;
  margin-bottom: 20px;
}

.price-list table {
  width: 100%;
  border-collapse: collapse;
}

.price-list th, .price-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.price-list th {
  background-color: #f2f2f2;
}
</style>