<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <!-- Contact us -->
  <section class="contact-section">
    <div class="container">
      <div class="contact-info-area">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 d-flex" v-for="item in ContactUS" :key="item.id" data-aos="fade-down"
            data-aos-duration="1200" data-aos-delay="0.1">
            <div class="single-contact-info flex-fill">
              <span><i :class="item.Icon"></i></span>
              <h3>{{ item.Title }}</h3>
              <a href="javascript:;">{{ item.Content }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-info-area" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="0.5">
        <div class="row">
          <div class="col-lg-6">
            <img src="@/assets/img/contactus.jpg"  style="width: 592px; height: 614px;" class="img-fluid" alt="Contact" />
          </div>
          <div class="col-lg-6">
            <form @submit.prevent="sendEmail">
               <div class="row">
                <h1>Get in touch!</h1>
                <div class="col-md-12">
                  <div class="input-block">
                    <label>Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.name" name="name">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label>Email Address <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.email" name="email">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label>Phone number <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.number" name="number">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label>Comments <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4" cols="50" v-model="form.message" name="comments">
											</textarea>
                  </div>
                </div>
              </div>
<!--              <button class="btn contact-btn">Send Enquiry</button>-->
              <button type="submit" :disabled="loading">
                {{ loading ? "Sending..." : "Send" }}
              </button>

              <p v-if="responseMessage" :class="{'success': isSuccess, 'error': !isSuccess}">
                {{ responseMessage }}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Contact us -->
  <scroll></scroll>
  <layout-footer></layout-footer>
</template>

<script>

import ContactUS from "@/assets/json/contact-us.json";
import emailjs from "@emailjs/browser";

export default {
  data() {
    return {
      ContactUS: ContactUS,
      title: "Contact us",
      text: "Pages",
      text1: "Contact us",
        form: {
          name: "",
          email: "",
          number: "",
          message: "",
        },
        loading: false,
        responseMessage: "",
        isSuccess: false,
      };
  },
  methods: {
    sendEmail() {
      this.loading = true;

      // Define EmailJS parameters
      const serviceID = "service_4r8bfkn"; // Replace with your EmailJS Service ID
      const templateID = "template_4267c5n"; // Replace with your Template ID
      const publicKey = "1MxlBEyzWn5yyeru0"; // Replace with your Public Key

      // Send email using EmailJS
      emailjs
          .send(serviceID, templateID, this.form, publicKey)
          .then((response) => {
            this.responseMessage = "Message sent successfully!";
            this.isSuccess = true;
            console.log("Success!", response.status, response.text);
          })
          .catch((error) => {
            this.responseMessage = "Failed to send message. Please try again.";
            this.isSuccess = false;
            console.error("Failed...", error);
          })
          .finally(() => {
            this.loading = false;
            // Optionally clear the form fields
            this.form.name = "";
            this.form.email = "";
            this.form.number = "";
            this.form.message = "";
          });
    },
  },
};
</script>
